//import "./App.css";
import './assets/styles/global.css';
import Index from "./pages/index/Index.js";

function App() {
  return (
    <Index/>
  );
}

export default App;
